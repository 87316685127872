import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatDateTime, getDisplayPrice, getMealPlanLabelExtended } from '../../../../js/Utils.js';
import { ProductAllPrice } from '../../Common/Pricing/ProductAllPrice';
import { DMCExtras } from '../../Common/DMC/DMCExtras';
import { DMCPriceBreakDown } from '../../Common/DMC/DMCPriceBreakDown';
import { M3Icon } from '../../../Common/M3Icon.js';

export const TourRoomMealPlan = ({ mealplan, defaultMealPlanId, showPriceBar, showCost, enableShowPriceBreakDown, mealPlanIndex, handleMealPlanId, roomIndex, totalAccomodation, multiRoomSelected, handleOpenCancelPolicyDetail, totalMealplan, templateInfo, handleSelectedExtras, supplier }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const [cultureName] = useState(cookies.get("CultureName"));
    const [labelPagamaento, setLabelPagamaento] = useState("");
    const [labelCancelPolicy, setLabelCancelPolicy] = useState("");
    const [classCancelPolicy, setClassCancelPolicy] = useState("");
    const [attribute, setAttribute] = useState("");
    const [attributeTooltip, setAttributeTooltip] = useState("");
    const [selectExtras, setSelectExtras] = useState([]);
    const [isDisabled, setIsDisabledSelect] = useState(true);
    const [optionPricing, setOptionPricing] = useState({});

    useEffect(() => {
        if (totalAccomodation === 1 && mealPlanIndex === 0) {
            handleMealPlanId(mealplan.id);
        }

        let updateOptionPricing = { ...mealplan.pricing }

        try {

            for (let i = 0; i < mealplan.extras.length; i++) {
                let extra = mealplan.extras[i];

                if (extra.mandatory && extra.amount)
                    updateOptionPricing.displayPrice += extra.amount;
            }

        } catch (ex) {

        }

        setOptionPricing(updateOptionPricing);

        getCancelPolicy();
        getAttributes();
    }, [mealPlanIndex]);

    useEffect(() => {
        if (totalAccomodation === 1)
            setIsDisabledSelect(false);
        else {
            // Multi Room Case
            if (multiRoomSelected && multiRoomSelected.length >= (mealplan.sequence - 1))
                setIsDisabledSelect(false);
            else {
                setIsDisabledSelect(true);
            }
        }
    }, [...multiRoomSelected, defaultMealPlanId])

    function getAttributes() {
        if (mealplan.attributes && mealplan.attributes.length === 0)
            return;

        if (mealplan.attributes && mealplan.attributes.find(x => x === "PR")) {
            setAttribute("Package");
        }
        else if (mealplan.promotions) {
            setAttribute("Promotion");
            setAttributeTooltip(mealplan.promotions && mealplan.promotions[0])
        }
    }

    function getCancelPolicy() {
        if (!mealplan || !mealplan.cancellationPolicy)
            return;

        if (mealplan.cancellationPolicy.isFree) {
            setLabelPagamaento(t(`CancelPolicy:PayAfter`));
            setLabelCancelPolicy(t(`CancelPolicy:RefundableBeforeDate`) + formatDateTime(mealplan.cancellationPolicy.expirationDate, cultureName));
            setClassCancelPolicy("text-success");
        }
        else {
            setLabelPagamaento(t(`CancelPolicy:PayNow`));
            setLabelCancelPolicy(t(`CancelPolicy:NotRefundable`));
            setClassCancelPolicy("text-danger");
        }
    }

    function onSelectExtra(extra, isSelected) {
        let updateArray = [...selectExtras];
        let updateOptionPricing = { ...optionPricing };

        if (!isSelected) {
            if (extra.amount)
                updateOptionPricing.displayPrice -= extra.amount;

            updateArray = updateArray.filter(x => x.id !== extra.id);
        } else {
            if (extra.amount)
                updateOptionPricing.displayPrice += extra.amount;

            updateArray.push(extra);
        }

        setSelectExtras(updateArray);
        setOptionPricing(updateOptionPricing);

        handleSelectedExtras({ mealPlanId: mealplan.id, id: extra.id, isSelected: isSelected });
    }

    function handleClickMealPlan() {
        handleMealPlanId(mealplan.id);
    }

    function onOpenCancelPolicyDetail(event, cancellationPolicy) {
        event.stopPropagation();
        handleOpenCancelPolicyDetail(cancellationPolicy);
    }

    return (
        <div data-accomodation={mealplan.sequence} className="row bg-white row-hover py-1 border-bottom animate-up-3 mx-1 rounded mealPlanRoom">
            <div className="col-12 col-lg-6 ">
                {t(getMealPlanLabelExtended(mealplan.planId, true))}:
                <span title={attributeTooltip} className="text-warning mx-1">{attribute}</span>
                <p className="small">{mealplan.planName ? mealplan.planName : t(getMealPlanLabelExtended(mealplan.planId))}</p>
            </div>
            <div className={`col-12 col-lg-4 px-2 ${classCancelPolicy}`}>
                {
                    labelCancelPolicy && <>
                        {labelPagamaento}: <p className="small">{labelCancelPolicy}</p>
                        <span className="cursor-pointer" onClick={e => onOpenCancelPolicyDetail(e, mealplan.cancellationPolicy)}>
                            <M3Icon iconName="Info" externalClass="icon-10 text-dark cursor-pointer" />
                        </span>
                    </>
                }
            </div>
            <div className="col-12 col-lg-2 ">
                <div className="d-table">
                    <div className="d-table-row text-right w-100">
                        <div className="d-table-cell h6 w-100">
                            <span className="mt-0">{getDisplayPrice(optionPricing, cultureName, showCost, templateInfo)}</span>
                        </div>
                        <div className={(totalMealplan === 1 && totalAccomodation === 1 ? "invisible" : "") + " d-table-cell"}>
                            <input className="h5 bg-primary ml-2 inputCheckboxMealPlan"
                                type="checkbox"
                                value={mealplan.sequence + "_" + roomIndex}
                                data-accomodation={mealplan.sequence}
                                defaultChecked={totalAccomodation > 1 ? multiRoomSelected.find(x => x.id === mealplan.id) : mealPlanIndex === 0}
                                disabled={isDisabled}
                                checked={totalAccomodation === 1 ? defaultMealPlanId === mealplan.id : null}
                                onClick={e => handleClickMealPlan()}
                            />
                        </div>
                    </div>
                </div>
                <div className="d-table-cell w-100" m3Role="f_showPriceBar">
                    <div className="text-right pr-1">
                        <ProductAllPrice showPriceBar={showPriceBar}
                            showCost={showCost}
                            pricing={optionPricing} />
                    </div>
                    {mealplan.status && mealplan.status === 'OnRequest' && <div className="text-info pr-2">OnRequest</div>}
                    <div className="text-right pr-1">
                        <span className="text-warning mx-1">{supplier}</span>
                    </div>
                </div>
            </div>
            {
                enableShowPriceBreakDown && mealplan.priceBreakDown && mealplan.priceBreakDown.length > 0 &&
                <DMCPriceBreakDown priceBreakDownArr={mealplan.priceBreakDown} idAccordion={mealplan.sequence + "_" + roomIndex + "_" + mealPlanIndex} />
            }
            {
                enableShowPriceBreakDown && mealplan.priceBreakDown && mealplan.priceBreakDown.length === 0 && <></>
            }
            {
                mealplan.extras && mealplan.extras.length > 0 && mealplan.extras.map((extra, key) => {
                    return <React.Fragment key={key}>
                        <DMCExtras extra={extra} onSelect={onSelectExtra} />
                    </React.Fragment>
                })
            }
        </div>
    );
}